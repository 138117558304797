import React from 'react';
import { Link } from 'gatsby';

import { Layout, Typography } from '../components';

const NotFoundPage = () => (
  <Layout center>
    <div className="flex flex-col justify-center max-w-screen-lg px-4 py-20 mx-auto space-y-8 text-center">
      <Typography className="uppercase tracking-[0.1em]" size="lg" weight="bold">Erreur 404</Typography>
      <Typography size="mdxl" weight="light">
        Désolé, mais la page que vous essayez de voir n&apos;existe pas.
      </Typography>
      <Link className="border border-[#ccc] py-2 px-4 uppercase bg-white hover:bg-[#e6e6e6] transition-all duration-300 ease-in-out" to="/">
        <Typography className="tracking-[0.1em]" size="mdx">
          Retour à
          {' '}
          <Typography className="tracking-[0.1em]" variant="span" size="mdx" weight="bold">L&apos;accueil</Typography>
        </Typography>
      </Link>
    </div>
  </Layout>
);

export default NotFoundPage;
